import React from 'react'
import ReactDOM from 'react-dom'
import _ from 'lodash'
import { withDependencies } from '@wix/thunderbolt-ioc'
import { ViewerModel, ViewerModelSym } from '@wix/thunderbolt-symbols'
import { OOIComponentLoader } from './types'
import { MODULE_URL } from './constants'

async function loadRequireJS(moduleRepoUrl: string = MODULE_URL) {
	await new Promise((resolve, reject) => {
		const script = document.createElement('script')
		script.src = `${moduleRepoUrl}/requirejs-bolt@2.3.6/requirejs.min.js`
		script.onload = resolve
		script.onerror = reject
		document.head.appendChild(script)
	})
	// @ts-ignore
	window.define('lodash', [], () => _)
	// @ts-ignore
	window.define('reactDOM', [], () => ReactDOM)
	// @ts-ignore
	window.define('react', [], () => React)
}

// eslint-disable-next-line prettier/prettier
export default withDependencies([ViewerModelSym], ({ siteAssets }: ViewerModel): OOIComponentLoader => {
		let waitForRequireJsToLoad: Promise<unknown> | null = null
		return {
			loadComponent(componentUrl: string) {
				return new Promise(async (resolve, reject) => {
					waitForRequireJsToLoad =
						waitForRequireJsToLoad || loadRequireJS(siteAssets.clientTopology.moduleRepoUrl)
					await waitForRequireJsToLoad
					__non_webpack_require__(
						[componentUrl],
						(module: any) => resolve(module?.default?.component),
						reject
					)
				})
			},
		}
	}
)
